@import '../../common.scss';

.container {
  background-color: $gray-color-face;

  > .mockup {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;
    padding: 4rem 0;
    overflow: hidden;

    // Responsive
    @include mobile {
      padding: 1rem 0;
    }

    > .image {
      width: 105.4rem * 0.5;
      height: auto;

      // Responsive
      @include mobile {
        max-width: 100%;
      }
    }
  }
  > .bar {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;

    > .items {
      @include limiter;
      display: flex;
      list-style: none;
      padding: 0;

      // Responsive
      @include mobile {
        flex-direction: column;
      }

      > li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        height: 6rem;
        padding-left: 2rem;

        // Responsive
        @include wide-desktop {
          border-left: 1px solid $gray-color-line;

          &:last-child {
            border-right: 1px solid $gray-color-line;
          }
        }
        @include desktop {
          &:not(:first-child) {
            border-left: 1px solid $gray-color-line;
          }
        }
        @include tablet {
          &:not(:first-child) {
            border-left: 1px solid $gray-color-line;
          }
        }
        @include mobile {
          height: initial;
          padding-top: 1rem;
          padding-bottom: 1rem;

          &:not(:first-child) {
            border-top: 1px solid $gray-color-line;
          }
        }

        > h3 {
          color: $gray-color-font;
          font-size: 0.9rem;
          font-weight: bold;

          // Responsive
          @include mobile {
            font-size: 1.2rem;
          }
        }
        > p {
          margin-top: 0.4rem;
          font-size: 0.9rem;
          font-weight: bold;
          line-height: 1.4;

          // Responsive
          @include mobile {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
