@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  background-color: $gray-color-face;

  > .limiter {
    @include limiter;

    > h1 {
      font-weight: bold;
      font-size: 7.2rem;
      line-height: 1.4;
      letter-spacing: -1px;

      // Responsive
      @include desktop {
        font-size: 7rem;
      }
      @include tablet {
        font-size: 5.6rem;
      }
      @include mobile {
        font-size: 3.2rem;
      }

      > .ball {
        width: 13.2rem * 0.5;
        margin-bottom: 1.4rem;

        // Responsive
        @include tablet {
          width: 13.2rem * 0.4;
        }
        @include mobile {
          width: 13.2rem * 0.2;
          margin-bottom: 0.8rem;
        }
      }
      > .block {
        display: inline-block;
      }
      > .accent {
        color: #006cff;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
