@import '../../common.scss';

.container {
  > .bottom {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;
    padding: 4rem 0;

    > .limiter {
      @include limiter;

      > .wrapper {
        display: flex;

        // Responsive
        @include tablet {
          flex-direction: column;
        }
        @include mobile {
          flex-direction: column;
        }

        > h2 {
          flex: 1;
          font-weight: bold;
          font-size: 3.2rem;
          line-height: 1.4;

          // Responsive
          @include tablet {
            font-size: 3rem;
          }
          @include mobile {
            font-size: 2.8rem;
          }
        }
        > div {
          flex-basis: 50%;
          padding: 0 4rem;

          // Responsive
          @include tablet {
            padding: initial;
          }
          @include mobile {
            padding: initial;
          }

          > h3 {
            margin-top: 1rem;
            font-size: 1.6rem;
            font-weight: bold;

            // Responsive
            @include tablet {
              margin-top: 2rem;
            }
            @include mobile {
              margin-top: 2rem;
            }
          }
          > p {
            margin-top: 3rem;
            font-size: 1.2rem;
            line-height: 1.8;
          }
        }
      }
    }
  }
}
