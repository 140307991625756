@import '../../common.scss';

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 4rem;
  overflow-x: hidden;
  background-color: black;

  > .wrapper {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 20s linear infinite;

    > .track {
      display: flex;
      list-style: none;

      > li {
        color: white;
        font-size: 1.4rem;
        margin: 0 1rem;
      }
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
