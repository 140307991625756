@use 'sass:color';
@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $gray-color-line;
  background-color: $gray-color-face;

  > .limiter {
    @include limiter;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: -1px;

    // Responsive
    @include wide-desktop {
      border-left: 1px solid $gray-color-line;
    }

    > .cell {
      width: calc(100% / 3);
      height: 34rem;
      border-right: 1px solid $gray-color-line;
      border-bottom: 1px solid $gray-color-line;

      // Responsive
      @include tablet {
        width: 50%;
      }
      @include mobile {
        width: 100%;
      }

      &.category {
        list-style: none;

        // Responsive
        @include mobile {
          height: initial;
          border-bottom: none;
          border-right: none;
        }

        > li {
          display: flex;
          align-items: center;
          height: 6rem;
          border-bottom: 1px solid $gray-color-line;
          padding-left: 2rem;
          cursor: pointer;

          &:hover,
          &.active {
            background-color: white;
          }
          > div {
            display: flex;
            align-items: flex-start;
            font-size: 3rem;
            font-weight: bold;

            > sup {
              font-size: 1.2rem;
              margin-left: 4px;
            }
          }
        }
      }
      &.work {
        padding: 0.7rem;
        display: none;
        cursor: pointer;

        // Responsive
        @include desktop {
          &:nth-of-type(3n - 1) {
            border-right: none;
          }
        }
        @include tablet {
          &:nth-of-type(2n - 1) {
            border-right: none;
          }
        }
        @include mobile {
          border-right: none;
        }

        &.show {
          display: flex;
          flex-direction: column;
        }
        &:hover {
          background-color: white;

          > .thumb {
            filter: initial;
          }
        }
        > .thumb {
          flex: 1;
          position: relative;
          background-color: color.adjust($gray-color-face, $lightness: -20%);
          background-size: cover;
          background-position: center;
          filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");

          // Responsive
          @include tablet {
            filter: initial;
          }
          @include mobile {
            filter: initial;
          }

          > .label {
            display: flex;
            top: 0;
            right: 0;
            align-items: center;
            padding: 0 0.5rem;
            position: absolute;
            height: 1.5rem;
            font-size: 0.7rem;
            color: white;
            background-color: black;
            line-height: 1;

            // Responsive
            @include mobile {
              height: 1.8rem;
              font-size: 1rem;
            }
          }
        }
        > .info {
          margin-top: 1rem;
          padding: 0 0.5rem;

          > .client {
            font-size: 0.8rem;

            // Responsive
            @include mobile {
              font-size: 1rem;
            }
          }
          > .title {
            margin-top: 0.2rem;
            font-size: 1.6rem;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          > .works {
            margin-top: 0.4rem;
            margin-bottom: 0.4rem;
            font-size: 0.8rem;
            color: $gray-color-font;

            // Responsive
            @include mobile {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
