@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  padding: 4rem 0;

  // Responsive
  @include mobile {
    padding: 2rem 0;
  }

  > .limiter {
    @include limiter;

    // Responsive
    @include mobile {
      padding: 0 1rem;
    }

    > .pagination {
      font-size: 1.2rem;
      color: white;

      // Responsive
      @include mobile {
        display: none;
      }
    }
    > .slider {
      display: flex;
      align-items: center;

      > .button {
        padding: 1rem 0;
        cursor: pointer;

        // Responsive
        @include mobile {
          display: none;
        }

        > .arrow {
          width: 5.03rem * 0.5;
        }
      }
      > .imageWrapper {
        flex: 1;
        display: flex;
        justify-content: center;

        > .image {
          opacity: 0;
          width: 0;
          height: auto;
          transform: translateX(2rem);
          transition: transform 1s, opacity 1s;

          &.active {
            opacity: 1;
            width: 82.8rem * 0.5;
            transform: translateX(0);

            // Responsive
            @include tablet {
              width: 100%;
            }
            @include mobile {
              width: 100%;
            }
          }
        }
      }
    }
    > .bottomNav {
      display: none;
      justify-content: center;
      align-items: center;

      // Responsive
      @include mobile {
        display: flex;
      }

      > .pagination {
        font-size: 1.2rem;
        color: white;
      }
      > .button {
        padding: 1rem;

        > .arrow {
          width: 5.03rem * 0.4;
          margin-bottom: 0.2rem;
        }
      }
    }
  }
}
