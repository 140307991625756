@import '../../common.scss';

.container {
  padding-top: 4rem;
  margin-top: -4rem;

  > .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;
    background-color: $gray-color-face;
    padding: 4rem 0;

    > .limiter {
      @include limiter;

      > h1 {
        width: 100%;
        max-width: 80%;
        font-weight: bold;
        font-size: 2.8rem;
        line-height: 1.6;

        // Responsive
        @include tablet {
          max-width: initial;
        }
        @include mobile {
          font-size: 2rem;
          max-width: initial;
        }
      }
    }
  }
  > .tabbar {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;
    background-color: $gray-color-face;

    > .tabs {
      @include limiter;
      display: flex;
      list-style: none;
      padding: 0;

      > li {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6rem;
        font-size: 1.6rem;
        font-weight: bold;
        cursor: pointer;

        // Responsive
        @include wide-desktop {
          &:first-child {
            border-left: 1px solid $gray-color-line;
          }
          &:last-child {
            border-right: 1px solid $gray-color-line;
          }
        }
        @include tablet {
          height: 5rem;
          font-size: 1.5rem;
        }
        @include mobile {
          height: 4rem;
          font-size: 1.4rem;
        }

        &:not(:first-child) {
          border-left: 1px solid $gray-color-line;
        }
        &:hover,
        &.active {
          background-color: white;
        }
      }
    }
  }
  > .bottom {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;
    padding: 4rem 0;

    > .limiter {
      @include limiter;

      > .wrapper {
        display: flex;

        // Responsive
        @include tablet {
          flex-direction: column;
        }
        @include mobile {
          flex-direction: column;
        }

        > h2 {
          flex: 1;
          font-weight: bold;
          font-size: 2.8rem;
          line-height: 1.4;

          // Responsive
          @include mobile {
            font-size: 2rem;
          }
        }
        > p {
          flex-basis: 60%;
          font-size: 1.3rem;
          line-height: 2;
          padding: 0 4rem;

          // Responsive
          @include tablet {
            margin-top: 1rem;
            padding: initial;
          }
          @include mobile {
            font-size: 1.2rem;
            line-height: 1.8;
            margin-top: 1rem;
            padding: initial;
          }
        }
      }
    }
  }
}
