@import '../../common.scss';

.container {
  padding-top: 4rem;
  margin-top: -4rem;

  > .wrapper {
    display: flex;
    justify-content: center;
    background-color: black;
    padding: 4rem 0;

    > .limiter {
      @include limiter;
      display: flex;

      // Responsive
      @include tablet {
        flex-direction: column;
      }
      @include mobile {
        flex-direction: column;
      }

      > .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > h1 {
          font-size: 3.2rem;
          font-weight: bold;
          color: white;
          line-height: 1.4;

          > .block {
            display: inline-block;
          }
        }
        > h2 {
          margin-top: 2rem;
          font-size: 1.7rem;
          color: white;
          cursor: pointer;
          letter-spacing: 0.1px;
        }
      }
      > .right {
        flex-basis: 28rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        // Responsive
        @include mobile {
          margin-top: 2rem;
          flex-basis: initial;
          align-items: center;
        }

        > input {
          margin-top: 2rem;
          width: 100%;
          max-width: 28rem;
          background-color: transparent;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid white;
          font-size: 1.4rem;
          padding-bottom: 0.5rem;
          color: white;
          outline: 0;

          &::placeholder {
            color: white;
          }
        }
        > .button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 4rem;
          height: 4.4rem;
          border: 1px solid white;
          border-radius: 2.2rem;
          color: white;
          font-size: 1.6rem;
          font-weight: bold;
          width: 100%;
          max-width: 16rem;
          cursor: pointer;
          transition: background-color 0.2s, color 0.2s;

          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }
  }
}
