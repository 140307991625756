@import '../common.scss';

.container {
  background-color: $gray-color-face;

  > .infoWrapper {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    border-bottom: 1px solid $gray-color-line;

    > .info {
      @include limiter;
      list-style: none;
      display: flex;
      flex-direction: row;

      // Responsive
      @include tablet {
        flex-wrap: wrap;
      }
      @include mobile {
        flex-direction: column;
        align-items: center;
      }

      > li {
        flex: 1;

        // Responsive
        @include tablet {
          margin-bottom: 2rem;
          flex: initial;
          width: 50%;
        }
        @include mobile {
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        > h3 {
          color: $gray-color-font;
          font-size: 0.9rem;

          &:not(:first-child) {
            margin-top: 1rem;
          }

          // Responsive
          @include tablet {
            font-size: 1.2rem;
          }
          @include mobile {
            font-size: 1.2rem;
            text-align: center;
          }
        }
        > p {
          margin-top: 0.4rem;
          font-size: 0.9rem;
          line-height: 1.4;

          // Responsive
          @include tablet {
            font-size: 1.2rem;
          }
          @include mobile {
            font-size: 1.2rem;
            text-align: center;
          }

          > .br {
            @include mobile {
              display: none;
            }
          }
        }
      }
    }
  }
  > .geoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;

    > .geo {
      font-size: 3.2rem;
      font-weight: bold;

      // Responsive
      @include mobile {
        font-size: 2rem;
      }
    }
    > .ball {
      margin-top: 1.5rem;
      width: 10rem * 0.5;
      cursor: pointer;
    }
    > .copyright {
      margin-top: 1rem;
      font-size: 0.9rem;
      font-weight: bold;
    }
  }
}
