@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $gray-color-line;
  background-color: $gray-color-face;

  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    height: 6rem;

    > .arrowBack {
      width: 5.03rem * 0.5;
      margin-right: 1rem;
      cursor: pointer;
    }
    > h1 {
      font-weight: bold;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}
