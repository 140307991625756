$gray-color-line: gray;
$gray-color-font: #777;
$gray-color-face: #f4f4f4;

$limit-width: 1440px;

@mixin font-en {
  font-family: 'Lato', sans-serif;
}

@mixin wide-desktop {
  @media screen and (min-width: 1441px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1069px) and (max-width: 1440px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 735px) and (max-width: 1068px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 734px) {
    @content;
  }
}

@mixin limiter {
  width: 100%;
  max-width: $limit-width;
  padding: 0 2rem;
}
