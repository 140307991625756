@import '../../common.scss';

.container {
  background-color: $gray-color-face;
  padding-bottom: 4rem;

  > .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;
    padding: 4rem 0;

    // Responsive
    @include mobile {
      padding-bottom: 3rem;
    }

    > .limiter {
      @include limiter;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      // Responsive
      @include mobile {
        flex-direction: column;
        align-items: initial;
      }

      > h1 {
        font-weight: bold;
        font-size: 4.4rem;

        // Responsive
        @include mobile {
          font-size: 2.8rem;
        }
      }
      > .button {
        font-size: 1.2rem;
        cursor: pointer;

        // Responsive
        @include mobile {
          margin-top: 1.6rem;
        }

        > .arrow {
          width: 3.85rem * 0.5;
          margin-left: 1rem;
        }
      }
    }
  }
  > .list {
    list-style: none;

    > li {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid $gray-color-line;
      background-color: $gray-color-face;
      cursor: pointer;

      &:hover {
        background-color: white;
      }
      > .item {
        @include limiter;
        height: 4rem;
        display: flex;
        align-items: center;

        // Responsive
        @include mobile {
          height: initial;
          padding: 1rem 2rem;
          line-height: 1.4;
        }

        > .title {
          flex: 1;
          font-size: 1.5rem;
          font-weight: bold;

          // Responsive
          @include mobile {
            font-size: 1.2rem;
          }
        }
        > .category {
          flex-basis: 14rem;
          font-size: 1.5rem;
          color: $gray-color-font;

          // Responsive
          @include tablet {
            display: none;
          }
          @include mobile {
            display: none;
          }
        }
        > .year {
          font-size: 1.5rem;
          font-weight: bold;

          // Responsive
          @include mobile {
            display: none;
          }
        }
      }
    }
  }
}
