@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  border-top: 1px solid $gray-color-line;
  border-bottom: 1px solid $gray-color-line;
  background-color: $gray-color-face;

  // Responsive
  @include tablet {
    border-bottom: none;
  }
  @include mobile {
    border-bottom: none;
  }

  > .limiter {
    @include limiter;
    display: flex;
    padding: 0;

    // Responsive
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      flex-direction: column;
    }

    > .column {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 3rem 2rem;
      cursor: pointer;

      // Responsive
      @include wide-desktop {
        border-left: 1px solid $gray-color-line;

        &:last-child {
          border-right: 1px solid $gray-color-line;
        }
      }
      @include desktop {
        &:last-child {
          border-left: 1px solid $gray-color-line;
        }
      }
      @include tablet {
        border-bottom: 1px solid $gray-color-line;
      }
      @include mobile {
        padding: 2rem;
        border-bottom: 1px solid $gray-color-line;
      }

      &:hover {
        background-color: white;

        > .image {
          filter: grayscale(0%);
        }
      }
      > .info {
        flex: 1;

        &.left {
          margin-right: 1rem;
        }
        &.right {
          margin-left: 1rem;
          text-align: right;
        }
        > .label {
          font-size: 1.2rem;
          font-weight: bold;
        }
        > .title {
          margin-top: 2rem;
          font-size: 2rem;
          font-weight: bold;

          // Responsive
          @include mobile {
            font-size: 1.6rem;
            line-height: 1.5;
          }
        }
        > .works {
          margin-top: 0.5rem;
          font-size: 0.8rem;
          color: $gray-color-font;

          // Responsive
          @include mobile {
            font-size: 1rem;
          }
        }
        > .arrow {
          margin-top: 2rem;
          width: 3.85rem * 0.5;
        }
      }
      > .image {
        width: 21.2rem * 0.5;
        border-radius: 1rem;
        filter: grayscale(100%);

        // Responsive
        @include tablet {
          width: 21.2rem * 0.4;
          filter: grayscale(0%);
        }
        @include mobile {
          width: 21.2rem * 0.4;
          filter: grayscale(0%);
        }
      }
    }
  }
}
