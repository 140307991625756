@import '../common.scss';

$navbar-height: 4rem;

.container {
  height: $navbar-height;

  .navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $gray-color-line;
    background-color: $gray-color-face;
    position: fixed;
    z-index: 100;

    > .limiter {
      @include limiter;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 0;

      // Responsive
      @include wide-desktop {
        border-left: 1px solid $gray-color-line;
        border-right: 1px solid $gray-color-line;
      }

      > .logo {
        cursor: pointer;

        &.full {
          width: 19.6rem * 0.5;
        }
        &.ball {
          width: 2.6rem * 0.8;
        }
      }
      > .menus {
        display: flex;
        flex-direction: row;
        height: $navbar-height;

        > .menu {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
          font-weight: bold;
          border-left: 1px solid $gray-color-line;
          cursor: pointer;

          // Responsive
          @include wide-desktop {
            width: 12rem;
          }
          @include desktop {
            width: 10rem;
          }
          @include tablet {
            width: 9rem;
          }
          @include mobile {
            width: 8rem;

            &:nth-child(1),
            &:nth-child(3) {
              display: none;
            }
          }

          &:hover {
            background-color: white;
          }
        }
      }
    }
  }
}
