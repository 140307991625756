@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $gray-color-line;
  padding: 4rem 0;
  background-color: $gray-color-face;

  // Responsive
  @include mobile {
    padding: 3rem 0;
  }

  > .limiter {
    @include limiter;

    > h1 {
      font-weight: bold;
      font-size: 4.4rem;

      // Responsive
      @include mobile {
        font-size: 2.2rem;
      }
    }
  }
}
